<template>
    <div class="among-content">
        <div class="among-among">
            <img class="among-image" src="../../assets/images/amongImg.jpg" />
            <h1>注册成功</h1>
            <p>页面将在{{ timeNum }}秒后自动跳转</p>
            <div>
                <router-link to="/login"><div class="among-button">立即跳转</div></router-link>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  data () {
    return {
      timeNum: 3
    }
  },
  mounted () {
    this.stepNum()
    window._agl && window._agl.push(['track', ['success', { t: 18 }]])
  },
  methods: {
    stepNum () {
      if (this.timeNum > 0) {
        setTimeout(() => {
          this.timeNum = this.timeNum - 1
          this.stepNum()
        }, 1000)
      } else {
        this.$router.push('/login')
      }
    }
  }
}
</script>

<style scoped>
    .among-content{
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f8f8f8;
        min-height: 100vh;
    }
    .among-image{
        width: 220px;
        height: 200px;
    }
    .among-among{
        width: 90%;
        height: 50vh;
        background: #fff;
        margin-bottom: 10vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    .among-button{
        width: 150px;
        height: 30px;
        border-radius: 15px;
        border: 1px solid #333;
        text-align: center;
        line-height: 30px;
    }
</style>
